import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: muscle snatch & oh band pull aparts`}</p>
    <p>{`Power Snatch 4×6\\@70% 1rm`}</p>
    <p>{`then, 3 rounds for time:`}</p>
    <p>{`20/15-Calorie Assault Bike`}</p>
    <p>{`8-Full Snatch (95/65)(RX+ 135/95)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      